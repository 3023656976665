import React from "react";
import { RxDoubleArrowDown } from "react-icons/rx";
import {faFacebook, faFacebookF, faGoogle, faYelp , faInstagram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Fade, Slide} from "react-awesome-reveal";
import img1 from "../img/41.png";
import img2 from "../img/40.png";
import img3 from "../img/39.png";
import img4 from "../img/38.png";
import Data from "../Components/Data";
import Menu from "../img/menu.png";
import videoIntro from "../img/video1.mov";

export default function Home(){

    function scrollSec(){
        const home1 = document.getElementById('Home1');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };

    return (
        <main className=" relative overflow-x-hidden">

            <section className="">
                <video playsInline = "true" src={videoIntro} loop="true" muted="true" autoPlay="autoplay" className='object-cover absolute h-screen w-screen -z-10 top-0 left-0'></video>
                <div className="bg-black py-5 flex flex-col items-center bg-opacity-40 h-screen w-screen justify-between tracking-wide text-white">
                    <div className=" flex flex-col h-screen justify-center">
                        <div className=" !font-serif space-y-8 text-center ">
                            <div className="">
                                <button onClick={() => {
                                    window.open(Menu);
                                }} className="hover:shadow-lg font-serif text-white hover:-translate-y-2 hover:bg-main-color hover:text-black ease-in-out delay-100 duration-150 text-2xl border-2 border-main-color py-4 w-[300px] mt-12 group cursor-pointer md:tracking-wide">
                                    <span className="">View Menu</span>
                                </button>
                            </div>
                            <div className=" flex justify-center space-x-5">
                                <div onClick={() => {
                                    window.location.href = 'https://www.facebook.com/profile.php?id=61560452603683';
                                }} className=" cursor-pointer  ease-in-out delay-100 duration-150 hover:bg-black/60 flex justify-center items-center shadow-xl px-4 py-2 bg-white rounded-full">
                                    <FontAwesomeIcon className=" w-[22px] h-[22px] text-sky-400" icon={faFacebookF} />
                                </div>
                                <div onClick={() => {
                                    window.location.href= 'https://www.instagram.com/phoduoibo/';
                                }} className=" cursor-pointer ease-in-out delay-100 duration-150 hover:bg-black/60 flex justify-center items-center shadow-xl px-4 py-2 bg-white rounded-full">
                                    <FontAwesomeIcon className=" w-[22px] h-[22px] text-rose-300" icon={faInstagram} />
                                </div>
                                <div onClick={() => {
                                    window.location.href= 'https://maps.app.goo.gl/f5uazVzuUQxL39g69';
                                }} className=" cursor-pointer ease-in-out delay-100 duration-150 hover:bg-black/60 flex justify-center items-center shadow-xl px-4 py-2 bg-white rounded-full">
                                    <FontAwesomeIcon className=" w-[22px] h-[22px] text-green-400" icon={faGoogle} />
                                </div>
                                <div onClick={() => {
                                    window.location.href= 'https://www.yelp.com/biz/pho-duoi-bo-san-jose';
                                }} className="cursor-pointer ease-in-out delay-100 duration-150 hover:bg-black/60 flex justify-center items-center shadow-xl px-4 py-2 bg-white rounded-full">
                                    <FontAwesomeIcon className=" w-[22px] h-[22px] text-rose-400" icon={faYelp} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 flex justify-center items-center">
                        <button onClick={() => scrollSec()} className="group ">
                            <RxDoubleArrowDown className="ease-in-out delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main-color text-5xl" />
                        </button>
                    </div>
                </div>
            </section>

            <section className=" bg-black" id="Home1">
                <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                            <Fade>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img1} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img3} />
                                    </div>
                                </div>
                            </Fade>
                            <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                                <Slide duration={1000} direction="down">
                                    <span className=" text-2xl text-white/40 font-PlayFair">About us</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-gray-400">
                                            Infusing Tradition with Contemporary
                                        </span>
                                        <span className="text-6xl text-main-color font-Freehand">
                                            Taste
                                        </span>
                                    </div>
                                    <p className=" text-xl text-white font-txt-font">
                                        At Phở Đuôi Bò, we bring the vibrant flavors of Vietnam right to your table. 
                                        Our restaurant has been a culinary sanctuary for those seeking authentic 
                                        Vietnamese cuisine in San Jose, California. Our specialty, Beef Pho, 
                                        is crafted using traditional recipes and the freshest ingredients, 
                                        including 'filet mignon' for the rare steak selections.                                    
                                    </p>
                                    <div className="">
                                        <button onClick={() => {
                                            window.open(Menu);
                                        }} className="hover:shadow-lg font-txt-font text-white hover:-translate-y-2 hover:bg-main2 hover:text-black ease-in-out delay-100 duration-150 mt-5 md:text-xl border-2 border-main2 text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                            <span className="">View Menu</span>
                                        </button>
                                    </div>
                                </Slide>
                            </div>
                            <Fade delay={5}>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" src={img2} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img src={img4} alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                
            </section>

            <section className=" lg:bg-fixed bg-cover bg-center bg-no-repeat bg-bgser p-5 pt-12 pb-8 lg:pt-24 lg:pb-24">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-lg">
                    <div className="flex flex-col text-center space-y-4">
                        <span className=" text-3xl lg:text-4xl text-yellow-100 font-Freehand">Taste of Vietnam</span>
                        <div className=" flex flex-col space-y-4">
                            <span className=" text-5xl lg:text-6xl font-header-font text-main-color">
                                Our Menus
                            </span>
                        </div>
                    </div>
                    <div className=" grid lg:grid-cols-3 gap-6 lg:gap-8">
                        {Data.map((i) => (
                            <Slide direction="left" delay={100}>

                                <div key={i.id} className="shadow-lg cursor-pointer h-fit rounded-lg mb-2 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                    <div className="overflow-hidden">
                                        <img className=" w-full" src={i.img} />
                                    </div>
                                    <div className="px-8 py-2 bg-black/50 space-y-4">
                                        <h1 className="text-2xl text-center capitalize text-white py-1">{i.name}</h1>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </div>
                </div>
            </section>

        </main>
    )
}