import img1 from "../img/36.png";
import img2 from "../img/37.png";
import img3 from "../img/35.png";

const Data = [
    {
        id:1,
        name: 'Phở Đuôi Bò Dặc Biệt',
        img: img3,
        // price: ''
    },
    {
        
        id:2,
        name: 'Phở Sườn Bò Đặc Biệt',
        img: img1,
        // price: ''
    },
    {
        id:3,
        name: 'Phở Tái Kobe',
        img: img2,
        // price: ''
    },
];

export default Data;