import React , {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmark , faPlus} from "@fortawesome/free-solid-svg-icons";

import Logo from "../img/Logo.png";
import img1 from "../img/36.png";
import img2 from "../img/37.png";
import img3 from "../img/35.png";

export default function Menu(){

    const [IsClick , setIsClick] = useState(false);

	const [id,setId] = useState(0);

    const handleBtn = (id) => {
        setIsClick((lastClick) => ({
            [id]: !lastClick[id],
        }));

		setId(id);
    };



	const Data = [
		{
			id: 1,
			title: 'Beef Pho',
			servicelist: [
				{
					id: 1,
					name: 'Phở Đuôi Bò Đặc Biệt',
					desc: 'Tái Filetmignon, Nạm, Chín, Gân, Sách, Bò Viên, Đuôi Bò',
					desc2: 'Filetmignon Steak, Well Done Flank, Lean Brisket, Tendon, Tripe, Beef Balls, Oxtails',
					price: '$21.95',
					img: img1
				},
				{
					id: 2,
					name: 'Phở Đuôi Bò Đặc Biệt',
					desc: 'Tái Filetmignon, Nạm, Chín, Gân, Sách, Bò Viên, Sườn Non',
					desc2: 'Filetmignon Steak, Well Done Flank, Lean Brisket, Tendon, Tripe, Beef Balls, Short Rib',
					price: '$21.95',
					img: img3
				},
				{
					id: 3,
					name: 'Phở Tái Kobe',
					desc: 'Pho Kobe',
					price: '$21.95',
					img: img2
				},
				{
					id: 4,
					name: 'Phở Filet Mignon Rare Steak',
					desc: 'Tái Filetmignon',
					desc2: 'Filetmignon Steak',
					price: '$16.95',
				},
				{
					id: 5,
					name: 'Phở Filet Mignon, Chín',
					desc: 'Tái Filetmignon, Chín',
					desc2: 'Filetmignon Steak, Lean Brisket',
					price: '$16.95',
				},
				{
					id: 6,
					name: 'Phở Filet Mignon, Nạm',
					desc: 'Tái Filetmignon, Nạm',
					desc2: 'Filetmignon Steak, Well Done Flank',
					price: '$16.95',
				},
				{
					id: 7,
					name: 'Phở Filet Mignon, Gân',
					desc: 'Tái Filetmignon, Gân',
					desc2: 'Filetmignon Steak, Tendon',
					price: '$16.95',
				},
				{
					id: 8,
					name: 'Phở Filet Mignon, Sách',
					desc: 'Tái Filetmignon, Sách',
					desc2: 'Filetmignon Steak, Tripe',
					price: '$16.95',
				},
				{
					id: 9,
					name: 'Phở Filet Mignon, Gầu',
					desc: 'Tái Filetmignon, Gầu',
					desc2: 'Filetmignon Steak, Fat Brisket',
					price: '$16.95',
				},
				{
					id: 10,
					name: 'Phở Filet Mignon, Nạm, Gân',
					desc: 'Tái Filetmignon, Nạm, Gân',
					desc2: 'Filetmignon Steak, Well Done Flank, Tendon',
					price: '$16.95',
				},
				{
					id: 11,
					name: 'Phở Filet Mignon, Nạm, Sách',
					desc: 'Tái Filetmignon, Nạm, Sách',
					desc2: 'Filetmignon Steak, Well Done Flank, Tripe',
					price: '$16.95',
				},
				{
					id: 12,
					name: 'Phở Filet Mignon, Nạm, Gầu',
					desc: 'Tái Filetmignon, Nạm, Gầu',
					desc2: 'Filetmignon Steak, Well Done Flank, Fat Brisket',
					price: '$16.95',
				},
				{
					id: 13,
					name: 'Phở Filet Mignon, Nạm, Vè Giòn',
					desc: 'Tái Filetmignon, Nạm, Vè Giòn',
					desc2: 'Filetmignon Steak, Well Done Flank, Crunchy Skirt Flank',
					price: '$16.95',
				},
				{
					id: 14,
					name: 'Phở Filet Mignon, Nạm, Gân, Sách',
					desc: 'Tái Filetmignon, Nạm, Gân, Sách',
					desc2: 'Filetmignon Steak, Well Done Flank, Tendon, Tripe',
					price: '$16.95',
				},
				{
					id: 15,
					name: 'Phở Filet Mignon, Gầu, Gân, Sách',
					desc: 'Tái Filetmignon, Gầu, Gân, Sách',
					desc2: 'Filetmignon Steak, Fat Brisket, Tendon, Tripe',
					price: '$16.95',
				},
				{
					id: 16,
					name: 'Phở Chín',
					desc: 'Chín',
					desc2: 'Lean Brisket',
					price: '$16.95',
				},
				{
					id: 17,
					name: 'Phở Chín, Nạm, Gầu',
					desc: 'Chín, Nạm, Gầu',
					desc2: 'Lean Brisket, Well Done Flank, Fat Brisket',
					price: '$16.95',
				},
				{
					id: 18,
					name: 'Phở Chín, Nạm, Gân',
					desc: 'Chín, Nạm, Gân',
					desc2: 'Lean Brisket, Well Done Flank, Tendon',
					price: '$16.95',
				},
				{
					id: 19,
					name: 'Phở Chín, Nạm, Sách',
					desc: 'Chín, Nạm, Sách',
					desc2: 'Lean Brisket, Well Done Flank, Tripe',
					price: '$16.95',
				},
				{
					id: 20,
					name: 'Phở Chín, Nạm, Gầu, Sách',
					desc: 'Chín, Nạm, Gầu, Sách',
					desc2: 'Lean Brisket, Well Done Flank, Fat Brisket, Tripe',
					price: '$16.95',
				},
				{
					id: 21,
					name: 'Phở Chín, Nạm, Gầu, Gân',
					desc: 'Chín, Nạm, Gầu, Gân',
					desc2: 'Lean Brisket, Well Done Flank, Fat Brisket, Tendon',
					price: '$16.95',
				},
				{
					id: 22,
					name: 'Phở Bò Viên',
					desc: 'Bò Viên',
					desc2: 'Beef Balls',
					price: '$16.95',
				},
				{
					id: 23,
					name: 'Phở Không Thịt',
					desc: 'No Meat Phở',
					price: '$11.95',
				}
				
			]
		},
		{
			id: 2,
			title: 'Add-Ons',
			servicelist: [
				{
					"id": 1,
					"name": "Đuôi Bò",
					"desc": "Ox Tails",
					"price": "$9.50"
				},

				{
					"id": 2,
					"name": "Sườn Non",
					"desc": "Short Rib",
					"price": "$9.50"
				},

				{
					"id": 3,
					"name": "Soup Bò 32oz",
					"desc": "Beef Soup 32oz",
					"price": "$8.00"
				},

				{
					"id": 4,
					"name": "Chén Bò Viên",
					"desc": "Bowl of Meat Balls",
					"price": "$7.50"
				},

				{
					"id": 5,
					"name": "Thêm Thịt",
					"desc": "Add More Meat (Filetmignon, Tendon, Tripe, Flank, Brisket)",
					"price": "$6.50"
				},

				{
					"id": 6,
					"name": "Thêm Bò Viên Vào Tô",
					"desc": "Add Beef Meatballs to the Bowl",
					"price": "$2.50"
				},

				{
					"id": 7,
					"name": "Thêm Phở",
					"desc": "Extra Noodle",
					"price": "$3.50"
				},

				{
					"id": 8,
					"name": "Dầu Cháo Quẩy",
					"desc": "Chinese Breadsticks",
					"price": "$2.00"
				},

				{
					"id": 9,
					"name": "Hành Dấm",
					"desc": "Onion Vinegar",
					"price": "$1.50"
				},

				{
					"id": 10,
					"name": "Hành Béo",
					"desc": "Fatty Broth Onion",
					"price": "$1.50"
				}
			]
			
		},

		{
			"id": 3,
			"title": "Coffee",
			"servicelist": [
			  {
				"id": 1,
				"name": "Càfe Sữa",
				"desc": "Vietnamese Iced Coffee w/ Condensed Milk",
				"price": "$6.50"
			  },
			  {
				"id": 2,
				"name": "Càfe Đá",
				"desc": "Vietnamese Iced Coffee",
				"price": "$6.50"
			  },
			  {
				"id": 3,
				"name": "Càfe Dừa",
				"desc": "Coconut Coffee",
				"price": "$6.50"
			  }
			]
		},

		{
			"id": 4,
			"title": "Smoothies",
			"servicelist": [
			  {
				"id": 1,
				"name": "Bơ",
				"desc": "Avocado Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 2,
				"name": "Dâu",
				"desc": "Strawberry Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 3,
				"name": "Xoài",
				"desc": "Mango Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 4,
				"name": "Thơm",
				"desc": "Pineapple Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 5,
				"name": "Dưa Gang",
				"desc": "Honeydew Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 6,
				"name": "Mocha",
				"desc": "Mocha Smoothie with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 7,
				"name": "Khoai Môn",
				"desc": "Taro Smoothie with Boba",
				"price": "$6.50"
			  }
			]
		},

		{
			"id": 5,
			"title": "Milk Tea",
			"servicelist": [
			  {
				"id": 1,
				"name": "Trà Xanh",
				"desc": "Matcha Milk Tea with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 2,
				"name": "Trà Sữa Truyền Thống",
				"desc": "Milk Tea with Boba",
				"price": "$6.50"
			  },
			  {
				"id": 3,
				"name": "Trà Sữa Khoai Môn",
				"desc": "Taro Milk Tea with Boba",
				"price": "$6.50"
			  }
			]
		},

		{
			"id": 6,
			"title": "Tea",
			"servicelist": [
			  {
				"id": 1,
				"name": "Trà Thái",
				"desc": "Thai Tea",
				"price": "$6.50"
			  },
			  {
				"id": 2,
				"name": "Trà Vải",
				"desc": "Lychee Tea",
				"price": "$6.50"
			  },
			  {
				"id": 3,
				"name": "Trà Đào",
				"desc": "Peach Tea",
				"price": "$6.50"
			  }
			]
		},

		{
			"id": 7,
			"title": "Soft Drinks",
			"servicelist": [
			  {
				"id": 1,
				"name": "Mía",
				"desc": "Sugar Cane",
				"price": "$6.50"
			  },
			  {
				"id": 2,
				"name": "Đá Chanh",
				"desc": "Strawberry Lemonade",
				"price": "$6.50"
			  },
			  {
				"id": 3,
				"name": "Nước Dừa",
				"desc": "Coconut Juice",
				"price": "$6.50"
			  },
			  {
				"id": 4,
				"name": "Soda Xí Muội",
				"desc": "Salted Plum Soda",
				"price": "$6.50"
			  },
			  {
				"id": 5,
				"name": "Coke, Diet Coke, Sprite",
				"desc": "",
				"price": "$2.50"
			  },
			  {
				"id": 6,
				"name": "Hot Tea",
				"desc": "",
				"price": "$2.50"
			  }
			]
		}
	]
	
    return (
        <section className= { `${IsClick[id]? "" : "w-screen h-screen"} bg-gradient-to-tr from-gray-400 to-gray-800 flex-1 space-y-5 overflow-hidden pb-8 pt-12`}>
			<div className=" flex justify-center">
				<img className=" w-32" src={Logo} />
			</div>
            <div className=" px-4 space-y-5 md:hidden">
                {Data.map((s) => (
                    <div className="grid grid-cols-1 overflow-auto">
                        <div className="">
                            <button className="w-full py-3 shadow-lg bg-black/70 text-main3 rounded-xl font-semibold tracking-wide text-center" key={s.id} onClick={() => handleBtn(s.id)}>
                                <div className="grid grid-cols-3 place-items-center">
                                    <div className="col-span-2 flex flex-col tracking-wider">
                                        <div className="text-2xl font-Dancing text-main3'">
                                            {s.title}
                                        </div>
                                    </div>
                                    <div>
                                        {!IsClick[s.id]?
                                            <FontAwesomeIcon className=" text-main3'" icon={faPlus} />: <FontAwesomeIcon className="text-main3" icon={faXmark} />
                                        }
                                    </div>
                                </div>
                            </button>
                            {IsClick[s.id] && (
                                <div className=" max-w-screen-lg mx-auto px-2">
									<div className=" grid grid-cols-1">
										{s.servicelist.map((g) => (
											<div key={g.id} className="shadow-lg bg-black/60 mt-2 rounded-xl mb-2">
												{g.img && (
													<div className=" p-2">
														<img className="h-full w-full" src={g.img} />
													</div>
												)}
												<div className="text-center text-white mx-2">
													<div className=" flex flex-col space-y-2 p-2">
														<h1 className="text-main3 text-xl">{g.id}. {g.name}</h1>
														<h2 className="font-Monterast italic">{g.desc}</h2>
														{g.desc2 && (
															<h3 className="italic font-thin">( {g.desc2} ) </h3>
														)}
														<h3 className="text-main3 text-lg font-Monterast italic">{g.price}</h3>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}