import React , {useState , useEffect} from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import Home from './Views/Home';
import FloatBtn from './Components/Floatbtn';
import Footer from './Components/Footer';
import SocialTree from './Views/Reviews';

function App() {

  const [activeTab, setActiveTab] = useState("Home");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const isActive = (tabName) => {
      return activeTab === tabName ? " border-t-main-color border-t-2 p-2" : "";
  };

  const checkTree = useLocation().pathname.includes('/Menu');


  // const location = useLocation();

  // useEffect(() => {

  //   const currentPath = location.pathname;

  //   switch (currentPath) {
  //     case "/About":
  //       setActiveTab("About");
  //       break;

  //     case "/Contact":
  //       setActiveTab("Contact");
  //       break;

  //     case "/Services":
  //       setActiveTab("Services");
  //       break;

  //     default:
  //       setActiveTab("Home");
  //       break;

  //   }
    
  // }, [location]);


  return (
    !checkTree? (
    	<main>
			<Navbar isActive = {isActive}  />
			<Routes>
			<Route path='/' element = {<Home />} />
			<Route path="*" element={<Navigate to="/" />} />
			</Routes>
			<FloatBtn />
			<Footer />
      	</main>
    ) : (
      	<Routes>
			<Route path = '/Menu' element = {<SocialTree />} />
		</Routes>
    )
    
  );
}

export default App;
