import {React , useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons"
function FloatBtn(){
    const [isThere , SetisThere] = useState(false)
    const changeisThere = () =>{
        if (window.pageYOffset > 150){
            SetisThere(true)
        }
        else{
            SetisThere(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <div className= {`${isThere?"opacity-100": "opacity-0"} shadow-lg text-white rounded-xl lg:hover:opacity-50 fixed bottom-8 text-center justify-center flex z-10 right-4 md:right-12 w-[180px] py-[8px] bg-gradient-to-t from-main-color to-yellow-200`}>
            <button onClick={() => {
                window.open("https://order.online/store/pho-duoi-bo-san-jose-30200016/?hideModal=true" , '__blank');
            }} className="cursor-pointer" type="button">
                <span className=" text-black text-xl font-txt-font">Order Now !</span>
            </button>
    </div>
    );
};

export default FloatBtn;